<script>
import ErrorPage from '@/views/ErrorPage';

export default {
  name: 'ErrorPageWrapper',
  functional: true,
  props: {
    errorStatus: {
      type: [String, Number],
      default: 404
    }
  },
  render(h, { props }) {
    return h(ErrorPage, {
      props
    });
  }
};
</script>
