<template functional>
  <v-col class="error-container">
    <v-row>
      <img
        class="error-container__image text-center align-center"
        :src="require(`@/assets/img/error_${props.allowedStatus.includes(String(props.errorStatus)) ? props.errorStatus : 404}.svg`)"
        alt="Error"
      >
    </v-row>
    <v-row class="justify-center">
      <h1 class="error-container__status text-center">
        {{ props.errorStatus }}
      </h1>
    </v-row>
    <v-row class="justify-center">
      <h2 class="error-container__title text-center">
        {{ parent.$t(`ErrorStatus.${props.errorStatus}.title`) }}
      </h2>
    </v-row>
    <v-row class="justify-center">
      <p class="error-container__body text-center">
        {{ parent.$t(`ErrorStatus.${props.errorStatus}.body`) }}
      </p>
    </v-row>
    <v-row class="justify-center">
      <v-btn
        class="error-container__button"
        rounded
        outlined
        color="primary"
        @click="parent.$router.go(-1)"
      >
        {{ parent.$t(`ErrorStatus.${props.errorStatus}.return`) }}
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'ErrorPage',
  props: {
    errorStatus: {
      type: [String, Number],
      required: true
    },
    allowedStatus: {
      type: Array,
      default: () => (['403', '404', '500'])
    }
  }
};
</script>

<style lang="scss" scoped>
  .error-container {
    height: 70vh;
    margin: auto;

    * {
      color: var(--gray-400);
    }

    &__image {
      margin: auto auto 20px;
    }

    &__status {
      font-size: 80px;
      font-weight: bold;
      line-height: 1.36;
    }

    &__title {
      font-size: 34px;
      line-height: 1.35;
      font-weight: bold;
    }

    &__body {
      font-size: 25px;
    }

    &__button {
      align-self: center;
      width: 188px;
    }
  }
</style>
