var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "v-col",
    { staticClass: "error-container" },
    [
      _c("v-row", [
        _c("img", {
          staticClass: "error-container__image text-center align-center",
          attrs: {
            src: require("@/assets/img/error_" +
              (_vm.props.allowedStatus.includes(String(_vm.props.errorStatus))
                ? _vm.props.errorStatus
                : 404) +
              ".svg"),
            alt: "Error"
          }
        })
      ]),
      _c("v-row", { staticClass: "justify-center" }, [
        _c("h1", { staticClass: "error-container__status text-center" }, [
          _vm._v("\n      " + _vm._s(_vm.props.errorStatus) + "\n    ")
        ])
      ]),
      _c("v-row", { staticClass: "justify-center" }, [
        _c("h2", { staticClass: "error-container__title text-center" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.parent.$t("ErrorStatus." + _vm.props.errorStatus + ".title")
              ) +
              "\n    "
          )
        ])
      ]),
      _c("v-row", { staticClass: "justify-center" }, [
        _c("p", { staticClass: "error-container__body text-center" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.parent.$t("ErrorStatus." + _vm.props.errorStatus + ".body")
              ) +
              "\n    "
          )
        ])
      ]),
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "error-container__button",
              attrs: { rounded: "", outlined: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.parent.$router.go(-1)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.parent.$t(
                      "ErrorStatus." + _vm.props.errorStatus + ".return"
                    )
                  ) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }